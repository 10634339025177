import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Using axios to call the API
import { Base_Url } from './globalvariable/globe';
import {Container, Modal, Button, Spinner,Col, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from './LanguageContext';
import Loader from './Loader';
import MyNavbar from './Navbar';

const Ewallet = () => {
  const [walletAmount, setWalletAmount] = useState(0);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const { translate, changeLanguage } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);


//   const handlePaymentSubmit = (val, paymentmethods) => {
//     // Assume that Razorpay or any other payment gateway setup will go here.
//     console.log('Payment details:', val, paymentmethods);
//   };
const handlePaymentSubmit = (val,meth) => {
    let amt = val.amount * 100

    var options = {
      // key: "rzp_live_HDJaK8R0U28N2k",
      key: "rzp_test_6rfv0AXZzpXeMu",
      amount: amt,
      currency: val.currency,
      name: "canteen app",
      order_id: val.id,
      description: "for testing purpose",
      handler: function (response) {
        if (response.razorpay_payment_id) {
        //   handleSelectChild()

        //   setTimeout(() => {
        //     handleSelectChildHide()
        //     navigate('/history')
        //   }, 3000);
        }

      },
      prefill: {
        name: val.notes.userName,
        email: val.notes.userEmail,
        contact: val.notes.userPhone
      },
      notes: {
        address: ""
      },
      theme: {
        color: "#3399cc"
      },
      modal: {
        ondismiss: function () {
          console.log('Checkout form closed');
        //   cartList(teacherId, schoolId)
        }
      }
    };
 
      // amount value is in multiples of 10000 
      options.config = {
        display: {
          blocks: {
            banks: {
              name: 'Pay via Fpx',
              instruments: meth
            },
          },
          sequence: ['block.banks'],
          preferences: {
            show_default_blocks: false,
          },
        },
      };
    
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
      console.log('Payment Failed:', response);
      // Handle failure logic here
      toast.error('Payment Failed! Please try again or choose a different payment method.');
      // setTimeout(() => {
      // }, 3000);

    });

    paymentObject.on('payment.modal.close', function () {
      console.log('Payment modal closed');

    });
    setIsLoading(false)

    paymentObject.open();

  }

  const pay = async () => {
    setIsLoading(true);
    const parentIDString = localStorage.getItem('hnhpapauserId');
    const bodyFormData = new FormData();
    bodyFormData.append('TeacherID', parentIDString);
    bodyFormData.append('walletamount', inputValue);
   
    try {
    
      const response = await axios.post(`${Base_Url}/addewallet`, bodyFormData);
      if (response.data.status === true) {
        handlePaymentSubmit(response.data.result.paymentDetails, response.data.result.paymentmethods);
        setInputValue('');
        setModalVisible(false);
        // localStorage.setItem('hnhpapateacherwallet', response.data.result.walletbalance);
      }
    } catch (err) {
      console.log('Error during wallet payment:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch the wallet data from an API
  const fetchWalletData = async () => {
   
   const storedValue=localStorage.getItem('hnhpapauserId')
    try {
    //   const response = await axios.get('YOUR_API_ENDPOINT'); // Replace with your actual API endpoint

    let response = await axios({
        method: 'post',
        url: `${Base_Url}/wallethistory`,
        data: {
          TeacherID: storedValue,
         
        }
      })
      const data = response.data.result;
      
      // Set the wallet amount and history from the API response
      setWalletAmount(data.walletamount);
      setTransactionHistory(data.ewallethistory);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  // Fetch the wallet data when the component mounts
  useEffect(() => {
    setIsLoading(true);
    fetchWalletData();
  }, []);

  return (
    
    <Container>
        {isLoading ? (
        <Loader /> // Show the loader when loading is true
      ) : (
        <>
           <MyNavbar />
            <ToastContainer />
            <Col xs={5} sm={7} md={5} lg={3} xl={3} className="mt-4">
              <Link to="/" className="link-no-underline ">
                <img src="leftarrow.png" alt="School Logo" className="pe-2 " />
                {translate('Back')}
              </Link>
            </Col>

        <Modal show={isModalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Add Money</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <input
                    type="number"
                    placeholder="Enter amount"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="amount-input"
                />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalVisible(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={pay}>
                    Add Money
                </Button>
                </Modal.Footer>
            </Modal>

            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            {/* E-Wallet Header */}
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h2>E-Wallet</h2>
                <h3>Available Balance</h3>
                <Row>
                  <Col lg={10}>
                    <div className='d-flex justify-content-between' style={{
                      backgroundColor: '#ff4500',
                      color: '#fff',
                      padding: '10px 0',
                      borderRadius: '8px',
                      fontSize: '24px',
                      fontWeight: 'bold'
                      }}>
                        <span className='mx-3'>Wallet balance</span>
                        <span className='text-end mx-3'>RM {walletAmount}</span>
                  
                      </div>
                  </Col>
                  <Col>
                    <div className='text-end'>
                      {/* <span className='text-mute'> Recharge Your Wallet </span> */}
                  
                      <button  style={{
                    
                      padding: '10px 20px',
                      backgroundColor: '#ff4500',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                       fontSize: '24px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                      }} onClick={() => setModalVisible(true)}>
                      Add Money
                      </button>
                    </div>
                  </Col>
                </Row>
                
               
            
            </div>

            {/* Transaction History */}
            <h3>Transaction History</h3>
            <div style={{ borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                {transactionHistory?.length > 0 ? (
                transactionHistory?.map((txn) => (
                    <div key={txn.EWalletID} style={{
                    border: '1px solid #e0e0e0',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                    }}>
                    <div>
                        <p>{txn.EWalletDescription}</p>
                        <p style={{ fontSize: '12px', color: '#666' }}>
                        Tx.Id: {txn.EWalletTxnID}<br />
                        {txn.EWalletCreatedOn}
                        </p>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <p style={{
                        fontSize: '20px',
                        color: txn.EWalletTxnType == 'Credit' ? 'green' : 'red'
                        }}>
                        {txn.EWalletTxnType=='Credit'?'+':'-'}{txn.EWalletTxnAmount}
                        </p>
                        <p style={{ fontSize: '12px', color: '#666' }}>
                        {txn.EWalletStatus}
                        </p>
                    </div>
                    </div>
                ))
                ) : (
                <p>No transaction history available.</p>
                )}
            </div>
            </div>
    </>
      )
    }
    </Container>
  );
};

export default Ewallet;
