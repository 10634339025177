import React, { useEffect, useState } from 'react'

import './custom.css'
import MyNavbar from './Navbar'
import { Container, InputGroup } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loader from './Loader';
import ReactPaginate from 'react-paginate'



export default function MyHistory() {
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  let navigate = useNavigate()
  const [datas, setData] = useState([])
  const [stuDetail, setStuDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selecteddDate, setSelecteddDate] = useState(new Date());
  const [slctdChild, setSlctdChild] = useState([])
  const [slctStatus, setSlctStatus] = useState([])

  const dateFromObject = new Date(selectedDate);
  const dateToObject = new Date(selecteddDate);
  const fromMonth = (dateFromObject.getMonth() + 1).toString().padStart(2, '0');
  const fromYear = dateFromObject.getFullYear();
  const fromDate = `${fromYear}-${fromMonth}`;

  const { selectedLanguage } = useTranslation();

  const toMonth = (dateToObject.getMonth() + 1).toString().padStart(2, '0');
  const toYear = dateToObject.getFullYear();
  const toDate = `${toYear}-${toMonth}`;
  console.log("change date", fromDate, "-", toDate)

  const [isChecked, setIsChecked] = useState([])
  const [isStatus, setIsStatus] = useState([])
  const [noChld, setNoChild] = useState(false)
  const [selectedFilterBrands, setSelectedFilterBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [clearStatus, setClearStatus] = useState(false)

  console.log(isStatus, "stat")
  const dayNamesMapping = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };
  const [isTypeStatus, setIsTypeStatus] = useState([])
  const [status, setStatus] = useState([])


  console.log({ isTypeStatus, selectedFilterBrands, isStatus });


  const updateStatus = (val) => {
    if (status.includes(val)) {
      // If val is in status array, remove it
      setStatus(prevStatus => prevStatus.filter(item => item !== val));
      setIsTypeStatus(prevStatus => prevStatus.filter(item => item !== val));

    } else {
      // If val is not in status array, add it
      setStatus(prevStatus => [...prevStatus, val]);
      setIsTypeStatus(prevStatus => [...prevStatus, val]);
    }
  };
  const fetchBrands = async () => {
    setLoading(true);
    const storedValue = localStorage.getItem('hnhpapauserId');
    const storedValues = localStorage.getItem('hnhpapaSchoolId');
    try {
      //   const response = await axios.get('/api/brands'); // Replace with actual brand API URL
      let response = await axios({
        method: 'post',
        url: `${Base_Url}/brands`,
        data: {
          TeacherID: storedValue,
          SchoolID: storedValues,
        }
      })
      if (response.data.status && response.data.result.brands) {
        setBrands(response.data.result.brands);
        // setFilteredBrands(response.data.result.brands)
        // setTimeSlots(response.data.result.timings)
        // fetchMenus(response.data.result.brands.at(0).BrandID)
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };
  const handleChildId = (childId) => {

    if (slctdChild.includes(childId)) {
      setSlctdChild(prevStatus => prevStatus.filter(item => item !== childId));
      setIsChecked(prevStatus => prevStatus.filter(item => item !== childId));
    } else {
      setSlctdChild(prevStatus => [...prevStatus, childId]);
      setIsChecked(prevStatus => [...prevStatus, childId]);
    }
  }
  const handlePageClick = () => {

  }

  const listDetails = async () => {
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');
    const childrenId = slctdChild.join(',')
    const childrenStatus = slctStatus.join(',')
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ChildrenID', childrenId)
      bodyFormData.append('TeacherID', teacherIDString)
      bodyFormData.append('TeacherSchoolID', storedValue)
      bodyFormData.append('PaymentStatus', childrenStatus)
      bodyFormData.append('OrderDateFrom', fromDate)
      bodyFormData.append('OrderDateTo', toDate)
      console.log(bodyFormData.values, "formdata")
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listorderhistory',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            console.log(res.data.result, 'test data');
            setData(res.data.result);
            // getChildrenDetails(res.data.result.SchoolID)
            setLoading(false)
            setNoChild(false)
          }
          else {
            setData([])
            setLoading(false)
            setNoChild(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)

        });
    }
    setShowChangesModal(false)
  }

  const handleSelectedStatus = (e) => {
    const updtStatus = e.target.value
    updateStatus(updtStatus)
  }
  const getdetails = async () => {
    setLoading(true)
    setClearStatus(false)
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');

    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      bodyFormData.append('TeacherSchoolID', storedValue)
      bodyFormData.append('orderedDate', selectedDate)
      bodyFormData.append('redeemStatus', isTypeStatus)
      bodyFormData.append('brand', selectedFilterBrands)
      bodyFormData.append('PaymentStatus', slctStatus)




      console.log(bodyFormData.values, "formdata")
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listorderhistory',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            console.log(res.data.result, 'test data');
            setData(res.data.result);
            // getChildrenDetails(res.data.result.SchoolID)
            setNoChild(false)
            setLoading(false)

          }
          else {
            setLoading(false)
            setData([]);
            // setNoChild(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          setLoading(false)

        });
    } else {
      navigate('/welcome')
    }
  }
  useEffect(() => {
    getdetails();
    fetchBrands();
    // getChildrenDetails();
  }, [])
  useEffect(() => {
    if (clearStatus == true) {
      getdetails();
    }

    // fetchBrands();
    // getChildrenDetails();
  }, [clearStatus])

  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [profileImage, setProfileImage] = useState(null);


  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showChangesModal, setShowChangesModal] = useState(false);
  const handleClearAll = () => {

    setSelectedDate('')
    setSelectedFilterBrands([]);
    setIsTypeStatus([])
    // setSelecteddDate(new Date())
    // setSlctdChild([])
    setSlctStatus([])
    setIsChecked([])
    setIsStatus([])
    setClearStatus(true)
    // setTimeout(() => {
    //   getdetails();
    // }, 1000);

    // getChildrenDetails();
  }
  const handleStatus = (e) => {
    const status = e.target.value
    if (slctStatus.includes(status)) {
      setSlctStatus(prevStatus => prevStatus.filter(item => item !== status));
      setIsStatus(prevStatus => prevStatus.filter(item => item !== status));
    } else {
      // If val is not in slctdChild array, add it
      setSlctStatus(prevStatus => [...prevStatus, status]);
      setIsStatus(prevStatus => [...prevStatus, status]);
    }

  }
  const handleCloseModal = () => {
    setShowChangesModal(false);
  };



  const boxStyle = {

    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  };

  const schoolName = {
    fontSize: "15px"
  };
  const placeColor = {
    fontSize: "15px"
  };
  const buttoncolor = {
    backgroundColor: '#F17C38',
    border: 'none'
  }

  const getChildrenDetails = async () => {
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    const schoolId = localStorage.getItem('hnhpapaSchoolId');
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      bodyFormData.append('TeacherSchoolID', schoolId)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listchildrens',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setStuDetail(res.data.result)
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)

        });
    } else {
      navigate('/welcome')
    }
  }
  console.log(stuDetail, "dff")


  const handleCheckboxClick = (brandID) => {
    setSelectedFilterBrands((prevSelected) =>
      prevSelected.includes(brandID)
        ? prevSelected.filter((id) => id !== brandID) // Uncheck
        : [...prevSelected, brandID] // Check
    );
  };

  // const handleClearAll = () => {
  //   setSelectedFilterBrands([]); // Clear selected brands
  //   setFilteredBrands(brands)
  //   setShowChangesModal(false);
  // };


  return (
    <div>
      {loading && stuDetail.length == 0 ? (
        <Loader />
      ) : (
        <>


          <Container>
            <MyNavbar />


            <div className="my-4">

              <Row className=" ">
                <Col xs={5} sm={7} md={5} lg={3} xl={3}>
                  <Link to="/" className="link-no-underline">
                    <img src="leftarrow.png" alt="School Logo" className="pe-1" /><span className="" style={{ fontSize: '15px' }}>{translate('Order History')}</span>
                  </Link>
                </Col>
                <Col className='text-end me-2'>
                  <img onClick={() => setShowChangesModal(true)} src={process.env.PUBLIC_URL + '/filter_list.svg'} />
                </Col>

              </Row>
            </div>


            {datas && datas.length > 0 ? (
              datas.map((item, index) => (
                <div key={index}>
                  <Row className="mx-1">
                    <div style={boxStyle} className="border border-gray-100 my-1 py-3 px-3">
                      <Link to={`/payment/${item.OrderID}`} className="link-no-underline">
                        {/* Payment and Redemption Status */}
                        <Row className="flex align-items-center mb-3">
                          <Col xs={6}>
                            <div className={`font-weight-bold ${item.PaymentStatus == 'Pending' ? 'text-warning' : item.PaymentStatus == 'Completed' ? 'text-success' : 'text-danger'}`}>
                              {item.PaymentStatus == 'Pending' ? 'Payment Pending' : item.PaymentStatus == 'Completed' ? 'Payment Success' : 'Payment Failed'}
                            </div>

                            <div className="text-muted">#{item.orderAutogenId}</div>
                            <div className="text-muted">{item.OrderDate}</div>
                          </Col>
                          <Col xs={6} className="text-end">
                            <div className={item.OrderStatus=='Redeemed' ? 'text-success' : 'text-danger'} style={{ fontSize: '15px' }}>
                              {item.OrderStatus}
                            </div>
                          </Col>
                        </Row>

                        {/* Online Orders */}
                        {item.onlineMenus && item.onlineMenus.length > 0 && (
                          <div className='border rounded p-2 my-2'>
                            <h6 className="font-weight-bold text-center">Online Orders</h6>
                            {item.onlineMenus.map((brand, brandIndex) => (
                              <div key={brandIndex} className="mb-3">
                                <Row>
                                  <Col xs={2}>
                                    <img src={Image_Url + brand.BrandLogo} alt={brand.BrandName} style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                                  </Col>
                                  {/* <Col xs={6}>
                                    
                                  </Col> */}
                                  <Col className="text-end text-muted mb-3">Ordered From {brand.VendorName}
                                    <div className="font-weight-bold">{brand.BrandName}</div></Col>
                                </Row>
                                <ul className="list-unstyled">
                                  {brand.menusDetails.map((menu, menuIndex) => (
                                    <li key={menuIndex} className="d-flex justify-content-between">
                                      <div>
                                        <span className="font-weight-bold">{selectedLanguage === 'en' ? menu.titleenglish : menu.titlechinese}</span>
                                      </div>
                                      <span>x{menu.count}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* Offline Orders */}
                        {item.offlineMenus && item.offlineMenus.length > 0 && (
                          <div className='border rounded p-2'>
                            <h6 className="font-weight-bold text-center">Offline Orders</h6>
                            {item.offlineMenus.map((menu, menuIndex) => (
                              <div key={menuIndex} className="mb-2">
                                <Row>
                                  <Col xs={2}>
                                    <img src={Image_Url + '/canteen_logo.png'} alt="Canteen" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                                  </Col>

                                  <Col className="text-end text-muted">Ordered From Canteen  <div className="font-weight-bold">Canteen</div></Col>
                                </Row>
                                <ul className="list-unstyled">
                                  <li className="d-flex justify-content-between">
                                    <div>
                                      <span className="font-weight-bold">{selectedLanguage === 'en' ? menu.titleenglish : menu.titlechinese}</span>
                                    </div>
                                    <span>x{menu.count}</span>
                                  </li>
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}
                      </Link>
                    </div>
                  </Row>
                </div>
              ))
            ) : (
              !loading && noChld && (
                <Col lg={12} xl={6} md={12}>
                  <Row className="mx-1">
                    <div style={boxStyle} className="border border-gray-100 my-1 py-3 px-3">
                      <p style={{ color: '#E85C33' }}>{translate('No Order History Found')}</p>
                    </div>
                  </Row>
                </Col>
              )
            )}




            {/* <Modal show={showModal} onHide={handleCloseModal}  >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">Add Children</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '15px' }}>

            <div className="mb-3">
              <label htmlFor="profileUpload" className="profile-uploader">
                <input
                  type="file"
                  id="profileUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleProfileImageChange}
                />
                <div className="profile-image-container">
                  {profileImage ? (
                    <img src={profileImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                  ) : (
                    <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                      <img src="childprofile.png" alt="profileImage" style={{ width: '65%', height: '65%' }} />
                    </div>
                  )}

                </div>
              </label>
            </div>

            <Form>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Javelin Jev" />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>IC No</Form.Label>
                <Form.Control type="email" placeholder="84984" />
              </Form.Group>



              <Form.Group controlId="formDropdown1" className="mb-3">
                <Form.Label>Grade</Form.Label>
                <div className="input-group">
                  <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                    borderRadius: '8px',
                    padding: '8px',
                    border: '1px solid #ccc'

                  }}>
                    <option defaultValue>III</option>
                    <option>IV</option>
                    <option>V</option>
                    <option>VI</option>
                    <option>VII</option>
                    <option>VIII</option>


                  </select>

                </div>
              </Form.Group>



              <Form.Group controlId="formDropdown1" className="mb-3">
                <Form.Label>Section</Form.Label>
                <div className="input-group">
                  <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                    borderRadius: '8px',
                    padding: '8px',
                    border: '1px solid #ccc'
                  }}>
                    <option defaultValue>A2</option>
                    <option>A1</option>
                    <option>A3</option>
                    <option>B1</option>
                    <option>B2</option>
                    <option>B3</option>


                  </select>

                </div>
              </Form.Group>

              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>School</Form.Label>
                <Form.Control type="text" placeholder="St.Mary Goretti Hr.Sec School" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={handleCloseModal} style={{
              width: '100%',
              backgroundColor: '#E85C33',
              borderColor: '#E85C33',
            }}>
              Create Child
            </Button>
          </Modal.Footer>
        </Modal> */}

            {pageCount > 1 ?
              <div className='my-5 d-flex justify-content-center'>
                <ReactPaginate
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                  disabledClassName="disabled"
                />
              </div> : null}


            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showChangesModal}
              onHide={() => setShowChangesModal(false)}
              className=""

            >

              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none', color: '#303A5B' }} className="">{translate('Filter')}</Modal.Title>
              </Modal.Header>


              <div className="my-4 mx-3">
                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>
                  {translate('Date')}
                </div>

                <Row className="mb-2">
                  <Col >
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="dd MMMM yyyy"
                      className="custom-date-picker ms-2"
                    />
                  </Col>


                </Row>
                <hr />

                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Brands')}</div>

                <Row>
                  {brands?.map((val, ind) => (
                    <React.Fragment key={val.BrandID}>
                      <Col md={11} xs={11} sm={11} lg={11} xl={11}>
                        <div className="mb-3" style={{ color: '#6B6B6B' }}>
                          {val.BrandName}
                        </div>
                      </Col>

                      <Col md={1} sm={1} xs={1} lg={1} xl={1} className="text-end">
                        <input
                          type="checkbox"
                          className="order-check-form"
                          id={val.BrandID}
                          value={val.BrandID}
                          checked={selectedFilterBrands.includes(val.BrandID)}
                          onChange={() => handleCheckboxClick(val.BrandID)}
                        />
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>

                <hr />
                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Payment Status')}</div>
                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Pending')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox2"
                      label=""
                      value='Pending'
                      onClick={handleStatus}
                      checked={isStatus.includes('Pending')}

                    />
                  </Col></Row>
                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Completed')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox2"
                      label=""
                      value='Completed'
                      onClick={handleStatus}
                      checked={isStatus.includes('Completed')}

                    />
                  </Col></Row>
                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Cancelled')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox3"
                      label=""
                      value='Cancelled'
                      onClick={handleStatus}
                      checked={isStatus.includes('Cancelled')}

                    />
                  </Col></Row>
                <hr />
                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Type')}</div>

                <Row>
                  <Col md={11} xs={11} sm={11} lg={11} xl={11}>
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Redeemed')}</div>
                  </Col>

                  <Col md={1} sm={1} xs={1} lg={1} xl={1} className="text-end">
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="1"
                      label=""
                      value='1'
                      onClick={handleSelectedStatus}
                      checked={isTypeStatus.includes('1')}

                    />
                  </Col></Row>


                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}> {translate('Not Redeemed')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="0"
                      label=""
                      value='0'
                      onClick={handleSelectedStatus}
                      checked={isTypeStatus.includes('0')}
                    />
                  </Col></Row>



                <Row>
                  <Col md={6} lg={6} sm={6} xs={6} xl={6} className="text-start">
                    <button onClick={handleClearAll} className='btn btn-danger'><span className="">{translate('Clear all')}</span></button>
                  </Col>

                  <Col md={6} lg={6} sm={6} xs={6} xl={6} className="text-end" >
                    <Button onClick={getdetails} className="px-5 " style={{ fontColor: 'white', backgroundColor: '#E85C33', borderColor: '#E85C33' }}>
                      <span className=" "> {translate('Filter')}</span>
                    </Button>
                  </Col>
                </Row>


              </div>


            </Modal>

          </Container>
        </>
      )}
    </div>

  );
}